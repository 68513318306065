import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import callout from '../../../images/removal-reinsertion-pg-callout-img.webp'

const metaTags = {
  description: 'Learn about the removal procedure of SUPPRELIN® LA (histrelin acetate).',
  keywords: 'Homepage',
  title: 'Removal & Reinsertion | SUPPRELIN® LA (histrelin acetate)'
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>A new implant can be inserted if you, your family, and your child’s healthcare professional decide to continue treatment </h1>
        <ul className='brand-list'>
            <li>
                The SUPPRELIN<sup>&reg;</sup> LA implant for central precocious puberty (CPP) needs to be removed after 12 months
            </li>
        </ul>
      </Col>
    </Row>
    <Row>
        <Col xs={12} md={6}>
            <h2>SUPPRELIN<sup>&reg;</sup> LA is designed to allow for a few additional weeks of medicine beyond 12 months, so you can be flexible when making medical appointments </h2>
            <h2>Length of treatment </h2>
            <p>Your child's healthcare professional will decide when to end treatment so that puberty can begin as normal—usually around age 11 in girls and age 12 in boys.</p>
        </Col>
        <Col xs={12} md={6}>
            <img
              src={callout}
              alt="Majority families continue with SUPPRELIN LA"
              title="Majority families continue with SUPPRELIN LA"
            />
        </Col>
    </Row>
    <Row>
        <Col xs={12}>
            <h2>Follow-up visits are important</h2>
            <p>Your child must have regular visits with his or her pediatric endocrinologist. A healthcare professional will perform regular exams and blood tests to check for signs of puberty, measure height and weight, and may take wrist X-rays to track bone growth.</p>
        </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
      <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/patients/caregiver-resources/#video=3"
          LinkCaption="Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA"
        >
          Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
